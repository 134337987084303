import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import logo from '../assets/images/logo.svg'

const Header = props => (
  <header id="header" className="alt">
    <Link to="/" className="logo">
      <img
        src={logo}
        onError={props.onSvgError}
        alt="Dominique Van Haesendonck"
      />
    </Link>
    <nav>
      <button className="menu-link" onClick={props.onToggleMenu}>
        Menu
      </button>
    </nav>
  </header>
)

Header.propTypes = {
  onToggleMenu: PropTypes.func,
  onSvgError: PropTypes.func,
}

export default Header
