import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout'
import Banner from '../components/Banner'

class HomeIndex extends React.Component {
  render() {
    const data = this.props.data
    return (
      <Layout>
        <Helmet
          title="Dominique Van Haesendonck"
          meta={[
            { name: 'description', content: 'Dominique Van Haesendonck' },
            { name: 'keywords', content: 'Dominique Van Haesendonck, 2024, vlaanderen, nva, n-va, BVBA Van Haesendonck' },
          ]}
        ></Helmet>

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <BackgroundImage
              Tag="article"
              fluid={data.domWendy.childImageSharp.fluid}
              backgroundColor={`#040e18`}
            >
              <header className="major">
                <h3>Over Dominique</h3>
              </header>
              <Link to="/" className="link primary"></Link>
            </BackgroundImage>
            <BackgroundImage
              Tag="article"
              fluid={data.domHobbies.childImageSharp.fluid}
              style={{ backgroundPosition: 'top' }}
            >
              <header className="major">
                <h3>Hobbies</h3>
              </header>
              <Link to="/hobbies" className="link primary"></Link>
            </BackgroundImage>
            <BackgroundImage
              Tag="article"
              fluid={data.domProfessioneel.childImageSharp.fluid}
            >
              <header className="major">
                <h3>Professioneel</h3>
              </header>
              <Link to="/" className="link primary"></Link>
            </BackgroundImage>
            <BackgroundImage
              Tag="article"
              fluid={data.domHonden.childImageSharp.fluid}
            >
              <header className="major">
                <h3>Dieren</h3>
              </header>
              <Link to="/" className="link primary"></Link>
            </BackgroundImage>
            <BackgroundImage
              Tag="article"
              fluid={data.domPolitiek.childImageSharp.fluid}
            >
              <header className="major">
                <h3>Politiek</h3>
              </header>
              <Link to="/politiek" className="link primary"></Link>
            </BackgroundImage>
            <BackgroundImage
              Tag="article"
              fluid={data.domSponsoring.childImageSharp.fluid}
            >
              <header className="major">
                <h3>Sponsoring</h3>
              </header>
              <Link to="/" className="link primary"></Link>
            </BackgroundImage>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    domWendy: file(relativePath: { eq: "dominique-wendy.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    domHobbies: file(relativePath: { eq: "hobbies.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    domHonden: file(relativePath: { eq: "hondjes.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    domPolitiek: file(relativePath: { eq: "politiek.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    domProfessioneel: file(relativePath: { eq: "professioneel.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    domSponsoring: file(relativePath: { eq: "sponsoring.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default HomeIndex
