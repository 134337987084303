import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

const HobbiesPage = ({ data }) => (
  <Layout>
    <Helmet>
      <title>Dominique Van Haesendonck | Hobbies</title>
      <meta name="description" content="Dominique Van Haesendonck | Hobbies" />
    </Helmet>

    <BannerLanding
      title="Hobbies en Vrije Tijd"
      description="Op een boot op zee, zonder land in zicht, het is geweldig. Ik voel de natuur rondom mij, zijn pracht maar ook zijn kracht en grillen."
    />

    <div id="main">
      <section class="spotlights">
        <section>
          <Image fluid={data.vis.childImageSharp.fluid} className="image" />
          <div className="content">
            <div className="inner">
              <header className="major">
                <h2>(Zee)vissen</h2>
              </header>
              <p>
                Sinds mijn 14e ga ik al zeevissen. Al onze gevangen vissen eten
                we op. <br />
                Het is leuk afwisselende vangsten te hebben.{' '}
                <strong>
                  Zo komt er wijting, tong, zeebaars maar ook al eens een krab
                  uit het water
                </strong>
                . Vroeger vingen we veel kabeljauw, maar deze is verdwenen. Door
                de opwarming of overbevissing van de beroepsvisserij, wie kan
                het mij zeggen? Mijn grootste vangst aan onze Vlaamse kust? Een
                ruwe haai van 12 kg. Deze was een record op dat moment en deze
                ving ik voor de kust van Nieuwpoort met een stuk makreel als
                aas.
              </p>
              <p>
                Ik vis met zorg voor de natuur, ondermaatse vis en beschermde
                vissen zet ik terug.
              </p>
              <p>
                <strong>
                  Op een boot op zee, zonder land in zicht, het is geweldig. Ik
                  voel de natuur rondom mij, zijn pracht maar ook zijn kracht en
                  grillen.
                </strong>{' '}
                De opkomende zon aan een lege horizon is een foto waard. Het
                afval dat we mee opvissen brengen we terug naar de haven. Dat
                mag niet blijven ronddobberen want dieren kunnen daarin
                verstrikt geraken. De laatste jaren zien we in onze wateren
                geregeld bruinvis, dolfijnen of zeeleeuwen.
              </p>
            </div>
          </div>
        </section>
      </section>
      <section>
        <div className="inner">
          <header className="major">
            <h2>Romeinen uit Elewijt</h2>
          </header>
          <p>
            Ik ben erkend metaaldetectorist.{' '}
            <strong>
              Dat wil zeggen dat ik de velden bewandel met een metaaldetector.
            </strong>
            <br />
            Steeds met toestemming van de eigenaar af pachter. Want op een veld
            mag je niet zomaar wandelen. Je kan de geplante oogst beschadigen en
            dat willen we niet.
          </p>
          <p>
            <strong>
              Er komen muntjes, kogels, vingerhoedjes, knopen enz. uit de grond.
            </strong>{' '}
            Belangrijke vondsten dien je te melden aan de overheid.
          </p>
          <p>
            <strong>
              Ik ben lid van de Semse, waar we met verschillende mensen in groep
              gaan zoeken.
            </strong>{' '}
            Deze groep beslist dan of de vondsten geschonken worden aan de
            Heemkundige kring van Zemst. Daar is dan ook een klein museum,
            vlakbij de hoofdbibliotheek van Zemst, aan het plein van
            verdraagzaamheid.
          </p>
        </div>
      </section>
      <section className="spotlights">
        <section>
          <Image
            fluid={data.denarius.childImageSharp.fluid}
            className="image"
          />
          <div className="content">
            <div className="inner">
              <p>
                Mijn mooiste vondst? Vlak bij mijn huis, een romeinse zilveren
                muntje (een denarius) en een vrij grote bronzen munt (een "as"
                genoemd). Ook een fibula is in mijn collectie, een soort van
                sluitspeld om kledij te versieren. Helaas ook veel
                oorlogsmunitie van WO I en WO II. Ook Zemst staat in de
                geschiedenisboeken met pracht maar zeker ook met leed.
              </p>
            </div>
          </div>
        </section>

        <span className="image main">
          <Image fluid={data.domHobbies.childImageSharp.fluid} />
        </span>
      </section>
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query {
    domHobbies: file(relativePath: { eq: "hobbies.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2400, fit: COVER, cropFocus: ENTROPY) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    vis: file(relativePath: { eq: "vis.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800, fit: COVER, cropFocus: ENTROPY) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    denarius: file(relativePath: { eq: "denarius.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 450, fit: COVER, cropFocus: ENTROPY) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default HobbiesPage
