module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dominique Van Haesendonck - 2024.vlaanderen","short_name":"2024.vlaanderen","description":"A personal website for Dominique Van Haesendonck.","start_url":"/","background_color":"#F9B919","theme_color":"#F9B919","display":"minimal-ui","icon":"src/assets/images/logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
