import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

const Footer = ({ data }) => (
  <footer id="footer">
    <div className="inner">
      <header className="major">
        <h2>Maak kennis</h2>
      </header>

      <Image
        fixed={data.domPortret.childImageSharp.fixed}
        className="about left image"
      />

      <p>
        Dominique is geboren en getogen als{' '}
        <span class="highlight">ZELFSTANDIGE ONDERNEMER</span>, werkte meer dan
        20 jaar in het familiebedrijf{' '}
        <span class="highlight">BVBA VAN HAESENDONCK</span>, zet zich al
        jarenlang in voor het <span class="highlight">DIERENASIEL</span>, werd
        voorzitter van de lokale{' '}
        <span class="highlight">VERENIGING VAN ZELFSTANDIGEN</span>, is{' '}
        <span class="highlight">GEMEENTERAADSLID voor N-VA</span>, lid van het{' '}
        <span class="highlight">POLITIEBESTUUR</span>, is al jarenlang{' '}
        <span class="highlight">SPONSOR van lokale SPORT &amp; CULTUUR</span>,
        is zelf van jongsaf fervent <span class="highlight">ZEEVISSER</span>,
        enz…
      </p>
      <ul className="actions">
        <li>
          <Link to="/landing" className="button next">
            Maak kennis op facebook
          </Link>
        </li>
      </ul>
    </div>
  </footer>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        domPortret: file(relativePath: { eq: "dominique-portret.jpg" }) {
          childImageSharp {
            fixed(quality: 90, width: 400) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
