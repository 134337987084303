import React from 'react'
import PropTypes from 'prop-types'

const BannerLanding = (props) => (
    <section id="banner">
        <div className="inner">
            <header className="major">
                <h1>{props.title}</h1>
            </header>
            <div className="content">
                <p dangerouslySetInnerHTML={{__html: props.description}}></p>
            </div>
        </div>
    </section>
)

BannerLanding.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default BannerLanding
