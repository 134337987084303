import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/"
                activeClassName="active"
                partiallyActive={true}>Start</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/"
                activeClassName="active"
                partiallyActive={true}>Over Dominique</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/hobbies"
                activeClassName="active"
                partiallyActive={true}>Hobbies</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/"
                activeClassName="active"
                partiallyActive={true}>Dieren</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/"
                activeClassName="active"
                partiallyActive={true}>Professioneel</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/politiek"
                activeClassName="active"
                partiallyActive={true}>Politiek</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/"
                activeClassName="active"
                partiallyActive={true}>Sponsoring</Link></li>
            </ul>
        </div>
        <button className="close" onClick={props.onToggleMenu} href="#">Close</button>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
