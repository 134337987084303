import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

const PolitiekPage = ({ data }) => (
  <Layout>
    <Helmet>
      <title>Dominique Van Haesendonck | Hobbies</title>
      <meta name="description" content="Dominique Van Haesendonck | Politiek" />
    </Helmet>

    <BannerLanding
      title="Politiek"
      description="Iedereen heeft een mening en we verschillen allemaal. Dat is een gezond beginpunt."
    />

    <div id="main">
      <section id="one">
        <span className="image main">
          <Image fluid={data.veerle.childImageSharp.fluid} />
        </span>
        <div className="inner">
          <header className="major">
            <h2>Partijpolitiek</h2>
          </header>
          <p>
            Ik voel mij het meeste aangetrokken bij mijn partij,{' '}
            <strong>de N-VA</strong>. <br />
            En dit{' '}
            <strong>
              omdat deze de Vlaamse waarden het beste tracht te bewaken zonder
              extreem te zijn
            </strong>
            .{' '}
          </p>
          <p>
            Wij spreken Nederlands in onze eigen streek. En dit in een steeds
            veranderende wereld waar migratie tussen landen maar ook tussen
            continenten als normaal beschouwd word.
          </p>
          <p>
            Diversiteit kan een groot voordeel zijn, als we de juiste profielen
            aantrekken.{' '}
            <strong>
              We laten iedereen genieten van de lusten, maar ze moeten bijdragen
              aan de lasten
            </strong>
            . En ook de lasten kennen van ons sociaal vangnet dat behoort tot
            één van de betere van de wereld.
          </p>
          <p>
            Ik zal <strong>actief werken voor de Zemstse inwoner</strong>. In
            het grootste algemeen belang en de uitgaven goed besteden voor alle
            inwoners.
          </p>
        </div>
        <span className="image main">
          <Image fluid={data.kerstman.childImageSharp.fluid} />
        </span>
      </section>
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query {
    kerstman: file(relativePath: { eq: "kerstman.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2400, fit: COVER, cropFocus: ENTROPY) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    veerle: file(relativePath: { eq: "veerle.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 2400, fit: COVER, cropFocus: ENTROPY) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default PolitiekPage
